import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"
import { dateToLocalString } from "@/types/News/helper"

@Serializable()
export default class NewsDetail {
  @JsonProperty("news_id") id?: number

  @JsonProperty("news") detail?: string

  @JsonProperty() title?: string

  @JsonProperty({ name: "created_date", beforeDeserialize: dateToLocalString })
  createdDate?: string

  @JsonProperty({ type: DiamondFile }) file?: DiamondFile = new DiamondFile()

  @JsonProperty() year!: number
}
